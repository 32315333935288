import axios from 'axios'

class RedeemService {
    reddemCashbak(formData) {
        return axios
            .post('https://admin.bshappliancesdirect.co.uk/api/cashbacks/applications/', formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }})
            .then(response => {
                return (response.data);
            })
    }
}

export default new RedeemService();
