var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "content" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "form-block redeem-cashback" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "form" },
          [
            _c("h2", [_vm._v("Cashback Redemption")]),
            _vm.message
              ? _c(
                  "div",
                  {
                    staticClass: "alert alert-danger mb-4",
                    attrs: { role: "alert" }
                  },
                  [_vm._v(" " + _vm._s(_vm.message) + " ")]
                )
              : _vm._e(),
            _c("p", [
              _vm._v(
                "Please insert your Service Order Number, and upload a copy of your receipt. We will process your claim within the next 14 days and cashback will be returned to your original payment method. "
              )
            ]),
            _c("ValidationObserver", {
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var invalid = ref.invalid
                    return [
                      _c(
                        "form",
                        {
                          staticClass: "needs-validation",
                          attrs: {
                            novalidat: "",
                            enctype: "multipart/form-data"
                          },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.redeemCashback($event)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "E-mail",
                                  rules: "required|email"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(v) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.email,
                                                expression: "email"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-lg",
                                            class: {
                                              "is-invalid":
                                                v.invalid && v.validated
                                            },
                                            attrs: {
                                              type: "email",
                                              placeholder: "Email"
                                            },
                                            domProps: { value: _vm.email },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.email = $event.target.value
                                              }
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "invalid-feedback text-left"
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(v.errors[0]) + " "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Reference id",
                                  rules: "required"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(v) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.refId,
                                                expression: "refId"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-lg",
                                            class: {
                                              "is-invalid":
                                                v.invalid && v.validated
                                            },
                                            attrs: {
                                              type: "number",
                                              placeholder:
                                                "Service Order Number (Starts with 237)",
                                              maxlength: "10",
                                              pattern: "^[0-9]{10}$",
                                              id: "reference-id-input",
                                              name: "reference"
                                            },
                                            domProps: { value: _vm.refId },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.refId = $event.target.value
                                              }
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "invalid-feedback text-left"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    v.errors[0]
                                                      ? "A valid Service Order Number is required"
                                                      : ""
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("ValidationProvider", {
                                attrs: { name: "brand", rules: "required" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(v) {
                                        return [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.brand,
                                                  expression: "brand"
                                                }
                                              ],
                                              staticClass:
                                                "custom-select form-control form-control-lg",
                                              class: {
                                                "is-invalid":
                                                  v.invalid && v.validated
                                              },
                                              attrs: { name: "brand" },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.brand = $event.target
                                                    .multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "option",
                                                {
                                                  attrs: {
                                                    value: "",
                                                    selected: ""
                                                  }
                                                },
                                                [_vm._v("Select brand")]
                                              ),
                                              _vm._l(_vm.brands, function(opt) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: opt.id,
                                                    domProps: { value: opt.id }
                                                  },
                                                  [_vm._v(_vm._s(opt.name))]
                                                )
                                              })
                                            ],
                                            2
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "invalid-feedback text-left"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    v.errors[0]
                                                      ? "brand selection is required"
                                                      : ""
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("ValidationProvider", {
                                attrs: { name: "category", rules: "required" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(v) {
                                        return [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.category,
                                                  expression: "category"
                                                }
                                              ],
                                              staticClass:
                                                "custom-select form-control form-control-lg",
                                              class: {
                                                "is-invalid":
                                                  v.invalid && v.validated
                                              },
                                              attrs: { name: "category" },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.category = $event.target
                                                    .multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "option",
                                                {
                                                  attrs: {
                                                    value: "",
                                                    selected: ""
                                                  }
                                                },
                                                [_vm._v("Select category")]
                                              ),
                                              _vm._l(_vm.categories, function(
                                                opt
                                              ) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: opt.id,
                                                    domProps: { value: opt.id }
                                                  },
                                                  [_vm._v(_vm._s(opt.name))]
                                                )
                                              })
                                            ],
                                            2
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "invalid-feedback text-left"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    v.errors[0]
                                                      ? "category selection is required"
                                                      : ""
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "purchasedFrom",
                                  rules: "required"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(v) {
                                        return [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.purchasedFrom,
                                                  expression: "purchasedFrom"
                                                }
                                              ],
                                              staticClass:
                                                "custom-select form-control form-control-lg",
                                              class: {
                                                "is-invalid":
                                                  v.invalid && v.validated
                                              },
                                              attrs: { name: "purchasedFrom" },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.purchasedFrom = $event
                                                    .target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "option",
                                                {
                                                  attrs: {
                                                    value: "",
                                                    selected: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "Select purchased through"
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "option",
                                                { attrs: { value: "direct" } },
                                                [_vm._v("Direct")]
                                              ),
                                              _c(
                                                "option",
                                                {
                                                  attrs: { value: "retailer" }
                                                },
                                                [_vm._v(" Other Retailer")]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "invalid-feedback text-left"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    v.errors[0]
                                                      ? "purchasedFrom selection is required"
                                                      : ""
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "form-control form-control-lg file-selector",
                                attrs: { for: "" }
                              },
                              [
                                _vm.receiptFile == false
                                  ? _c("span", [_vm._v("Upload Receipt")])
                                  : _c("span", [
                                      _vm._v(" " + _vm._s(_vm.receiptFile.name))
                                    ]),
                                _c("ValidationProvider", {
                                  attrs: { name: "invoice", rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(v) {
                                          return [
                                            _c("input", {
                                              attrs: {
                                                accept:
                                                  "application/pdf, image/png, image/jpg, image/*, capture=camera, image/jpeg, application/doc, .docx",
                                                type: "file",
                                                id: "recieptInput"
                                              },
                                              on: { change: _vm.selectFile }
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "invalid-feedback text-left"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(v.errors[0]) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ]),
                          _c("p", { staticClass: "text-left note" }, [
                            _vm._v(
                              "*Acceptable forms of upload formats are: png, jpg, jpeg, pdf, doc, docx"
                            )
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                type: "Submit",
                                disabled: invalid || _vm.loading
                              }
                            },
                            [
                              _c("span", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.loading,
                                    expression: "loading"
                                  }
                                ],
                                staticClass: "spinner-grow spinner-grow-sm",
                                staticStyle: {
                                  width: "1.0625rem",
                                  height: "1.0625rem"
                                },
                                attrs: { role: "status", "aria-hidden": "true" }
                              }),
                              _vm._v(" Submit")
                            ]
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm._m(1)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [
      _c("div", { staticClass: "logo" }, [
        _c("img", {
          attrs: { src: require("./../../assets/images/logo.png"), alt: "BSH" }
        })
      ]),
      _c("div", { staticClass: "brand-logos d-flex" }, [
        _c("div", { staticClass: "logo" }, [
          _c("img", {
            attrs: {
              src: require("./../../assets/images/bosch-logo.png"),
              alt: "Bosch"
            }
          })
        ]),
        _c("div", { staticClass: "logo" }, [
          _c("img", {
            attrs: {
              src: require("./../../assets/images/siemens-logo.png"),
              alt: "siemens"
            }
          })
        ]),
        _c("div", { staticClass: "logo" }, [
          _c("img", {
            attrs: {
              src: require("./../../assets/images/neff.png"),
              alt: "neff"
            }
          })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", [
      _c("p", [
        _c(
          "a",
          {
            staticClass: "mr-3",
            attrs: {
              href: "https://www.bosch-home.co.uk/store/terms-of-website-use"
            }
          },
          [_vm._v("Term of use ")]
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.bosch-home.co.uk/data-protection-information"
            }
          },
          [_vm._v("Data Protection Policy")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }