<template>
  <main class="content">
    <div class="container">
      <div class="form-block redeem-cashback">
        <header>
          <div class="logo">
            <img src="./../../assets/images/logo.png" alt="BSH" />
          </div>
          <div class="brand-logos d-flex">
            <div class="logo">
              <img src="./../../assets/images/bosch-logo.png" alt="Bosch" />
            </div>
            <div class="logo">
              <img src="./../../assets/images/siemens-logo.png" alt="siemens" />
            </div>
            <div class="logo">
              <img src="./../../assets/images/neff.png" alt="neff" />
            </div>
          </div>
        </header>
        <div class="form">
          <h2>Cashback Redemption</h2>
          <div v-if="message" class="alert alert-danger mb-4" role="alert">
            {{ message }}
          </div>
          <p>Please insert your Service Order Number, and upload a copy of your receipt. We will process your claim
            within
            the next 14 days and cashback will be returned to your original payment method.
          </p>
          <ValidationObserver v-slot="{ invalid }">
            <form class="needs-validation" @submit.prevent="redeemCashback" novalidat enctype="multipart/form-data">
              <div class="form-group">
                <ValidationProvider name="E-mail" rules="required|email" v-slot="v">
                  <input type="email" class="form-control form-control-lg" placeholder="Email" v-model="email"
                    v-bind:class="{ 'is-invalid': v.invalid && v.validated }" />
                  <div class="invalid-feedback text-left">
                    {{ v.errors[0] }}
                  </div>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider name="Reference id" rules="required" v-slot="v">
                  <input type="number" v-model="refId" placeholder="Service Order Number (Starts with 237)"
                    class="form-control form-control-lg" maxlength="10" pattern="^[0-9]{10}$" id="reference-id-input"
                    name="reference" v-bind:class="{ 'is-invalid': v.invalid && v.validated }" />
                  <div class="invalid-feedback text-left">
                    {{ v.errors[0] ? 'A valid Service Order Number is required' : '' }}
                  </div>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider name="brand" rules="required" v-slot="v">
                  <select name="brand" v-model="brand" class="custom-select form-control form-control-lg"
                    v-bind:class="{ 'is-invalid': v.invalid && v.validated }">
                    <option value="" selected>Select brand</option>
                    <option v-for="opt in brands" :value="opt.id" :key="opt.id">{{ opt.name }}</option>
                  </select>
                  <div class="invalid-feedback text-left">
                    {{ v.errors[0] ? 'brand selection is required' : '' }}
                  </div>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider name="category" rules="required" v-slot="v">
                  <select name="category" v-model="category" class="custom-select form-control form-control-lg"
                    v-bind:class="{ 'is-invalid': v.invalid && v.validated }">
                    <option value="" selected>Select category</option>
                    <option v-for="opt in categories" :value="opt.id" :key="opt.id">{{ opt.name }}</option>
                  </select>
                  <div class="invalid-feedback text-left">
                    {{ v.errors[0] ? 'category selection is required' : '' }}
                  </div>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider name="purchasedFrom" rules="required" v-slot="v">
                  <select name="purchasedFrom" v-model="purchasedFrom"
                    class="custom-select form-control form-control-lg"
                    v-bind:class="{ 'is-invalid': v.invalid && v.validated }">
                    <option value="" selected>Select purchased through</option>
                    <option value="direct">Direct</option>
                    <option value="retailer"> Other Retailer</option>
                  </select>
                  <div class="invalid-feedback text-left">
                    {{ v.errors[0] ? 'purchasedFrom selection is required' : '' }}
                  </div>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label for="" class="form-control form-control-lg file-selector">
                  <span v-if="receiptFile == false">Upload Receipt</span>
                  <span v-else> {{ receiptFile.name }}</span>
                  <ValidationProvider name="invoice" rules="required" v-slot="v">
                    <input
                      accept="application/pdf, image/png, image/jpg, image/*, capture=camera, image/jpeg, application/doc, .docx"
                      type="file" @change="selectFile" id="recieptInput" />

                    <div class="invalid-feedback text-left">
                      {{ v.errors[0] }}
                    </div>

                  </ValidationProvider>

                </label>
              </div>
              <p class="text-left note">*Acceptable forms of upload formats are: png, jpg, jpeg, pdf, doc, docx</p>
              <button type="Submit" :disabled="invalid || loading" class="btn btn-primary"> <span v-show="loading"
                  class="spinner-grow spinner-grow-sm" style="width:1.0625rem;height:1.0625rem;" role="status"
                  aria-hidden="true"></span> Submit</button>
            </form>
          </ValidationObserver>
        </div>
        <footer>
          <p>
            <a href="https://www.bosch-home.co.uk/store/terms-of-website-use" class="mr-3">Term of use </a>
            <a href="https://www.bosch-home.co.uk/data-protection-information">Data Protection Policy</a>
          </p>
        </footer>
      </div>
    </div>
  </main>
</template>

<script>

import redeemService from '../../services/redeem.service';
import BrandService from "@/services/brand.service";
import CategoryService from "@/services/category.service";


export default {
  data() {
    return {
      receiptFile: false,
      refId: "",
      email: "",
      loading: false,
      message: "",
      brands: [],
      categories: [],
      brand: '',
      category: '',
      purchasedFrom: '',
    }
  },
  created() {
    this.getBrands();
    this.getCategories()
  },

  methods: {
    selectFile(e) {
      var recieptInput = document.getElementById("recieptInput").value;
      var idxDot = recieptInput.lastIndexOf(".") + 1;
      var extFile = recieptInput.substr(idxDot, recieptInput.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "pdf" || extFile == "doc" || extFile == "docx") {
        if (e.target.files.length > 0)
          this.receiptFile = e.target.files[0];
        else {
          console.log(e);
        }
      } else {
        this.message = "Only jpg, jpeg, png, pdf, doc and docx files are allowed!";
      }
    },
    getBrands: function () {
      BrandService.list().then((list) => {
        this.brands = list;
      });
    },
    getCategories: function () {
      CategoryService.roots().then((list) => {
        this.categories = list;
      });
    },
    async redeemCashback() {
      {
        this.loading = true;
        var form = new FormData();
        form.append("customer_email", this.email);
        form.append("customer_ref_no", this.refId);
        form.append('invoice', this.receiptFile);
        form.append('brand', this.brand);
        form.append('category', this.category);
        form.append('purchased_from', this.purchasedFrom);
        redeemService.reddemCashbak(form).then(
          () => this.$router.push("/redeem/thanks"),
          (error) => {
            this.loading = false;
            this.message =
              (error.response && Object.values(error.response.data).join(" and ")) ||
              error.message ||
              error.toString();
          }
        )
      }
    }
  }

}
</script>

<style></style>
